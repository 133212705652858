var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('h1',{staticClass:"text-center"},[_vm._v("人員管理")])])],1),_c('b-row',{staticClass:"mt-3",attrs:{"align-h":"between"}},[_c('b-col',{attrs:{"cols":"3"}},[_c('b-pagination',{attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"align":"center"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1),_c('b-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"d-flex"},[_c('b-btn',{directives:[{name:"b-modal",rawName:"v-b-modal.Person-modal",modifiers:{"Person-modal":true}}],staticStyle:{"min-width":"7rem"},attrs:{"variant":"primary"}},[_vm._v(" 增加新人員 ")]),_c('b-form-select',{staticClass:"ml-2",attrs:{"options":[
            { value: 5, text: '每頁5個人' },
            { value: 10, text: '每頁10個人' },
            { value: 20, text: '每頁20個人' }
          ]},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1)])],1),_c('b-row',[_c('b-col',[_c('b-table',{attrs:{"hover":"","per-page":_vm.perPage,"current-page":_vm.currentPage,"items":_vm.peopleList,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(toolbox)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"variant":"outline-primary","size":"sm","pill":"","title":"編輯"},on:{"click":function($event){return _vm.editBtn(data.item.id)}}},[_c('b-icon',{attrs:{"icon":"pencil-square"}})],1)],1)]}},{key:"cell(is_admin)",fn:function(data){return [(data.value === true)?_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v(" 管理員 ")]):_c('b-badge',{attrs:{"variant":"info"}},[_vm._v("評審員")])]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('b-pagination',{attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"align":"center"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1),_c('b-modal',{attrs:{"id":"Person-modal","size":"lg","centered":"","title":"增加新人員","lazy":""},on:{"ok":function($event){_vm.userForm.id === null
        ? _vm.newUser(_vm.userForm)
        : _vm.editUser(_vm.userForm.id, Object.assign({}, {passwd: _vm.userForm.password}, _vm.userForm))},"cancel":function($event){Object.keys(_vm.userForm).forEach(function (key) {
        _vm.userForm[key] = null;
      })}}},[_c('b-container',[_c('b-row',[_c('b-col',[_c('label',[_vm._v(" E-mail "),_c('b-form-input',{attrs:{"type":"email","autocomplete":"off","placeholder":"輸入 E-mail"},model:{value:(_vm.userForm.email),callback:function ($$v) {_vm.$set(_vm.userForm, "email", $$v)},expression:"userForm.email"}})],1)]),_c('b-col',[_c('label',[_vm._v(" 密碼 "),_c('b-form-input',{attrs:{"type":"password","autocomplete":"off","placeholder":"輸入 密碼"},model:{value:(_vm.userForm.password),callback:function ($$v) {_vm.$set(_vm.userForm, "password", $$v)},expression:"userForm.password"}})],1)]),_c('b-col',[_c('label',[_vm._v(" 選擇身份 "),_c('b-form-select',{attrs:{"options":[
                { value: true, text: '管理員' },
                { value: false, text: '評審員' }
              ]},model:{value:(_vm.userForm.is_admin),callback:function ($$v) {_vm.$set(_vm.userForm, "is_admin", $$v)},expression:"userForm.is_admin"}})],1)])],1),_c('b-row',[_c('b-col',[_c('label',[_vm._v(" 姓名 "),_c('b-form-input',{attrs:{"autocomplete":"off","placeholder":"輸入 姓名"},model:{value:(_vm.userForm.name),callback:function ($$v) {_vm.$set(_vm.userForm, "name", $$v)},expression:"userForm.name"}})],1)]),_c('b-col',[_c('label',[_vm._v(" 電話 "),_c('b-form-input',{attrs:{"type":"tel","autocomplete":"off","placeholder":"輸入 電話"},model:{value:(_vm.userForm.phone),callback:function ($$v) {_vm.$set(_vm.userForm, "phone", $$v)},expression:"userForm.phone"}})],1)]),_c('b-col',[_c('label',[_vm._v(" 地址 "),_c('b-form-input',{attrs:{"autocomplete":"off","placeholder":"輸入 地址"},model:{value:(_vm.userForm.address),callback:function ($$v) {_vm.$set(_vm.userForm, "address", $$v)},expression:"userForm.address"}})],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }