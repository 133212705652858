<template>
  <b-container fluid>
    <b-row class="mt-3">
      <b-col>
        <h1 class="text-center">人員管理</h1>
      </b-col>
    </b-row>
    <b-row class="mt-3" align-h="between">
      <b-col cols="3">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
        ></b-pagination>
      </b-col>

      <b-col cols="3">
        <div class="d-flex">
          <b-btn
            v-b-modal.Person-modal
            style="min-width:7rem;"
            variant="primary"
          >
            增加新人員
          </b-btn>

          <b-form-select
            class="ml-2"
            v-model="perPage"
            :options="[
              { value: 5, text: '每頁5個人' },
              { value: 10, text: '每頁10個人' },
              { value: 20, text: '每頁20個人' }
            ]"
          ></b-form-select>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          hover
          :per-page="perPage"
          :current-page="currentPage"
          :items="peopleList"
          :fields="fields"
        >
          <template #cell(toolbox)="data">
            <div class="d-flex justify-content-center">
              <b-btn
                variant="outline-primary"
                size="sm"
                pill
                @click="editBtn(data.item.id)"
                v-b-tooltip.hover
                title="編輯"
              >
                <b-icon icon="pencil-square"></b-icon>
              </b-btn>
            </div>
          </template>
          <template #cell(is_admin)="data">
            <b-badge v-if="data.value === true" variant="danger">
              管理員
            </b-badge>
            <b-badge v-else variant="info">評審員</b-badge>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
        ></b-pagination>
      </b-col>
    </b-row>

    <!-- 新增 or 更新 人員 -->
    <b-modal
      id="Person-modal"
      size="lg"
      centered
      title="增加新人員"
      lazy
      @ok="
        userForm.id === null
          ? newUser(userForm)
          : editUser(userForm.id, { passwd: userForm.password, ...userForm })
      "
      @cancel="
        Object.keys(userForm).forEach(key => {
          userForm[key] = null;
        })
      "
    >
      <b-container>
        <b-row>
          <b-col>
            <label>
              E-mail
              <b-form-input
                v-model="userForm.email"
                type="email"
                autocomplete="off"
                placeholder="輸入 E-mail"
              ></b-form-input>
            </label>
          </b-col>

          <b-col>
            <label>
              密碼
              <b-form-input
                v-model="userForm.password"
                type="password"
                autocomplete="off"
                placeholder="輸入 密碼"
              ></b-form-input>
            </label>
          </b-col>

          <b-col>
            <label>
              選擇身份
              <b-form-select
                v-model="userForm.is_admin"
                :options="[
                  { value: true, text: '管理員' },
                  { value: false, text: '評審員' }
                ]"
              >
              </b-form-select>
            </label>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <label>
              姓名
              <b-form-input
                v-model="userForm.name"
                autocomplete="off"
                placeholder="輸入 姓名"
              ></b-form-input>
            </label>
          </b-col>

          <b-col>
            <label>
              電話
              <b-form-input
                v-model="userForm.phone"
                type="tel"
                autocomplete="off"
                placeholder="輸入 電話"
              ></b-form-input>
            </label>
          </b-col>
          <b-col>
            <label>
              地址
              <b-form-input
                v-model="userForm.address"
                autocomplete="off"
                placeholder="輸入 地址"
              ></b-form-input>
            </label>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </b-container>
</template>

<script>
import { API } from "../store/api";
export default {
  name: "Management",
  computed: {},
  watch: {},
  async mounted() {
    this.getUsers(this.perPage, this.currentPage);
  },
  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      userForm: {
        id: null,
        email: null,
        password: null,
        name: null,
        phone: null,
        address: null,
        is_admin: false
      },
      fields: [
        {
          key: "name",
          label: "姓名",
          sortable: false
        },
        {
          key: "is_admin",
          label: "管理員權限",
          sortable: true
        },
        {
          key: "email",
          label: "E-mail",
          sortable: false
        },
        {
          key: "phone",
          label: "電話",
          sortable: false
        },
        {
          key: "address",
          label: "地址",
          sortable: false
        },
        {
          key: "created_at",
          label: "建立於",
          sortable: true
        },
        {
          key: "updated_at",
          label: "最後更新於",
          sortable: true
        },
        {
          key: "toolbox",
          label: "資料編輯",
          sortable: false
        }
      ],
      peopleList: []
    };
  },
  methods: {
    async getUsers() {
      let data = await API.GetUsers();
      this.totalRows = data.count;
      this.peopleList = data.users;
    },
    async newUser({ email, password, name, phone, address, is_admin }) {
      await API.NewUser({ email, password, name, phone, address, is_admin });
      await this.getUsers();
    },
    async editUser(userID, { passwd, name, phone, address, is_admin }) {
      await API.PUTUser(userID, {
        passwd,
        name,
        phone,
        address,
        is_admin
      });
      await this.getUsers();
    },
    editBtn(user_id) {
      this.userForm.password = "";
      this.userForm = { ...this.peopleList.find(p => p.id === user_id) };
      this.$bvModal.show("Person-modal");
    }
  }
};
</script>

<style lang="scss" scoped></style>
